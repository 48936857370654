import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { ChatRichtext } from '../components/atoms/chatRichtext';
import Layout from '../components/organisms/layout';
import SEO from '../components/organisms/seo';
import { Sketch } from '../components/atoms/sketch';
import { loadableP5 as P5Wrapper } from '../utils/loadable';
import { useClientSide } from '../hooks/useClientSide';
import { media } from '../utils/media-queries';

const ImpressumPage = ({ data }) => {
  const isClient = useClientSide();

  useEffect(() => {
    document.body.classList.add('white');
    return () => document.body.classList.remove('white');
  });

  const { buch } = data.gradients.nodes[0];

  if (isClient)
    window.addEventListener(
      'keydown',
      function (e) {
        if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
          e.preventDefault();
        }
      },
      false
    );

  return (
    <Layout bgSrc={buch.image.asset.fluid}>
      <SEO siteTitle="Impressum" />
      <div className="chats">
        <ChatRichtext>
          <p>
            Angaben gemäß § 5 TMG & inhaltlich verantwortlich gemäß §55 Abs. 2
            RstV:
          </p>
          <p>
            Prof. Dr. Christian Holler <br />
            c/o Hochschule München <br />
            Lothstr. 34 <br />
            80335 München <br />
            Telefon: +49-89-1265-1620 <br />
            E-Mail: info (at) ohne-heisse-luft.de <br />
          </p>

          <p>
            Prof. Dr. Joachim Gaukel
            <br />
            c/o Hochschule Esslingen
            <br />
            Robert-Bosch-Str. 1<br />
            73037 Göppingen
            <br />
            Telefon: +49-7161-679-1241
            <br />
            E-Mail: info (at) ohne-heisse-luft.de
          </p>

          <br />
          <p>Haftungsausschluss</p>
          <p>
            <p>
              Haftung für Inhalte <br />
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
              sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
              sind wir als Diensteanbieter jedoch nicht verpflichtet,
              übermittelte oder gespeicherte fremde Informationen zu überwachen
              oder nach Umständen zu forschen, die auf eine rechtswidrige
              Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
              der Nutzung von Informationen nach den allgemeinen Gesetzen
              bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
              erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <p>
              Haftung für Links <br />
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <p>
              Urheberrecht <br />
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
          </p>

          <br />
          <p>Datenschutzerklärung</p>
          <p>
            <p>
              Alle personenbezogenen Daten werden vertraulich und in
              Übereinstimmung mit den gesetzlichen Bestimmungen behandelt. Die
              Nutzung dieser Website ist ohne Angabe personenbezogener Daten
              möglich. Soweit auf der Website personenbezogene Daten
              (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben
              werden, erfolgt dies, soweit möglich, stets auf freiwilliger
              Basis.
            </p>
            <p>
              Die Website erhebt und speichert automatisch Daten in Server Log
              Files, die Ihr Browser übermittelt. Erhoben werden können:
              Browsertyp und -version, Betriebssystem, Referrer URL, Hostname
              des zugreifenden Rechners und Uhrzeit der Serveranfrage. Diese
              Daten sind nicht personenbezogen und werden nicht mit anderen
              Datenquellen zusammengeführt. Sollten uns konkrete Anhaltspunkte
              für eine rechtswidrige Nutzung bekannt werden, behalten wir uns
              vor, diese Daten nachträglich zu prüfen.
            </p>
            <p>
              Der für die Verarbeitung Verantwortliche verarbeitet und speichert
              die personenbezogenen Daten der betroffenen Person nur für den
              Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich
              ist oder sofern dies durch den Europäischen Richtlinien- und
              Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder
              Vorschriften, denen der für die Verarbeitung Verantwortliche
              unterliegt, vorgesehen wurde.
            </p>
            <p>
              Weitergabe an Dritte <br />
              Wir geben Ihre personenbezogenen Daten nur in den folgenden Fällen
              an Dritte weiter: <br />
              Artikel 6.1.b (notwendig für die Begründung oder Durchführung
              eines Vertragsverhältnisses) <br />
              Artikel 6.1.f (notwendig für die Verfolgung legitimer Zwecke und
              Interessen) <br />
              Für andere Zwecke geben wir Ihre personenbezogenen Daten nicht
              ohne Ihre Zustimmung an Dritte weiter.
            </p>
            <p>
              Ihre Rechte <br />
              Sie haben das Recht, Zugang zu Ihren personenbezogenen Daten sowie
              deren Berichtigung oder Löschung zu verlangen. Sie haben auch das
              Recht, die Verarbeitung Ihrer personenbezogenen Daten
              einzuschränken. Sie haben das Recht, eine von Ihnen erteilte
              Einwilligung, z. B. zum Erhalt eines E-Mail-Newsletters, jederzeit
              zu widerrufen. Im Falle eines Verstoßes gegen
              datenschutzrechtliche Bestimmungen haben Sie das Recht, bei der
              zuständigen Aufsichtsbehörde (Bayerisches Landesamt für
              Datenschutzaufsicht) Beschwerde einzulegen.
            </p>
          </p>
        </ChatRichtext>
        <StyledCanvas className="spacing-outer">
          <P5Wrapper sketch={Sketch} />
        </StyledCanvas>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Impressum {
    gradients: allSanityGradients {
      nodes {
        buch {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

const StyledCanvas = styled.div`
  border: var(--border);
  display: inline-block;
  background: var(--white);
  display: none;

  @media ${media.M} {
    display: block;
  }
`;

ImpressumPage.propTypes = {
  data: PropTypes.object,
};

export default ImpressumPage;
