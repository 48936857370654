/* eslint-disable no-plusplus */
import Bike from '../../assets/images/snake-rad.png';
import Banana from '../../assets/images/banana.png';

export function Sketch(p) {
  const width = 800;
  const height = 800;
  const scale = 100;
  const multiply = 1;
  let rows;
  let column;
  let img;
  let fruitImg;

  const snake = {
    x: 0,
    y: 0,
    xSpeed: scale * multiply,
    ySpeed: 0,
    total: 0,
    tail: [],
  };

  p.drawSnake = () => {
    for (let i = 0; i < snake.tail.length; i++) {
      p.image(img, snake.tail[i].x, snake.tail[i].y, scale, scale);
    }

    p.image(img, snake.x, snake.y, scale, scale);
  };

  p.updateSnake = () => {
    for (let i = 0; i < snake.tail.length - 1; i++) {
      snake.tail[i] = snake.tail[i + 1];
    }

    snake.tail[snake.total - 1] = {
      x: snake.x,
      y: snake.y,
    };

    snake.x += snake.xSpeed;
    snake.y += snake.ySpeed;

    if (snake.x > width) snake.x = 0;
    if (snake.y > height) snake.y = 0;
    if (snake.x < 0) snake.x = width;
    if (snake.y < 0) snake.y = height;
  };

  const fruit = {
    x: 0,
    y: 0,
  };

  p.fruitPos = () => {
    fruit.x = (Math.floor(Math.random() * rows - 1) + 1) * scale;
    fruit.y = (Math.floor(Math.random() * column - 1) + 1) * scale;
  };

  p.drawFruit = () => {
    p.fill('purple');
    p.noStroke();
    p.image(fruitImg, fruit.x, fruit.y, scale, scale);
  };

  p.eat = () => {
    if (snake.x === fruit.x && snake.y === fruit.y) {
      snake.total++;
      return true;
    }
    return false;
  };

  p.collision = () => {
    for (let i = 0; i < snake.tail.length; i++) {
      if (snake.x === snake.tail[i].x && snake.y === snake.tail[i].y) {
        snake.total = 0;
        snake.tail = [];
      }
    }
  };

  p.setup = () => {
    p.pixelDensity(4.0);
    p.createCanvas(width, height);
    rows = height / scale;
    column = width / scale;
    p.fruitPos();
    img = p.loadImage(Bike);
    fruitImg = p.loadImage(Banana);
  };

  p.draw = function () {
    p.frameRate(3);

    if (p.keyIsDown(p.UP_ARROW)) {
      snake.xSpeed = 0;
      snake.ySpeed = -scale * multiply;
    }
    if (p.keyIsDown(p.DOWN_ARROW)) {
      snake.xSpeed = 0;
      snake.ySpeed = scale * multiply;
    }
    if (p.keyIsDown(p.LEFT_ARROW)) {
      snake.xSpeed = -scale * multiply;
      snake.ySpeed = 0;
    }
    if (p.keyIsDown(p.RIGHT_ARROW)) {
      snake.xSpeed = scale * multiply;
      snake.ySpeed = 0;
    }

    p.background('#F0F4F8');
    p.drawFruit();
    p.updateSnake();
    p.drawSnake();

    p.collision();

    if (p.eat()) {
      p.fruitPos();
    }
  };
}
